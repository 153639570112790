<template>
  <div>
    <v-list-item>
        <v-select
        multiple
        outlined
        dense
        label="Automate Birthday SMS for"
        :items="sms.sms_options"
        class="pa-0"
        v-model="sms.sms_selected_options"
      />
    </v-list-item>
    <v-list-item>
      <v-btn
        :disabled="sms.saving"
        :loading="sms.saving"
        large
        block
        color="info outline"
        @click="smsSubmit"
        >Save</v-btn
      >
    </v-list-item>
    <br>
    <v-list-item>
        <div style="color: #333">
            Set BirthDay Sms
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
               Setup Sms For sending on Birthday for Staff and Students.  <br> <br> 
                <a href="javascript:void(0);"  @click="openDialog=true, compKey=compKey+1" >Edit template </a>
              </small><br> 
            </p>
          </div>
    </v-list-item>

    <v-dialog v-model="openDialog" persistent scrollable>
      <templateSetup :reuseComponentFlag="true" v-on:reset="resetAll()" :key="compKey" settingFor="birthday"></templateSetup>
    </v-dialog>
  </div>
</template>

<script>
import templateSetup from "../../../../sms/_components/Templates/index.vue"

export default({
	components:{templateSetup},

    data: ()=>({
        compKey:0,
        openDialog:false,
        sms:{
            sms_options:[
                { value: 'staff', text: 'Staff' },
                { value: 'student', text: 'Student' },
                { value: 'guardian', text: 'Guardian' },
            ],
            saving:false,
            sms_selected_options: "",
        }
    }),
    mounted(){
        this.getSmsSetting()
    },
    methods:{
        getSmsSetting(){
            this.$rest.get('/api/setting-sms').then((res)=>{
                this.sms.sms_selected_options = res.data ? res.data : "";
            })
        },
        smsSubmit(){
            this.sms.saving = true
            // this.$rest.post('/api/sms/sms-setting',{
            this.$rest.post('/api/setting-sms',{
                send_birthday_sms_for: this.sms.sms_selected_options
            ? this.sms.sms_selected_options.join(',')
            : '',
            }).then((res)=>{
                this.$events.fire('notification', { message: res.data.message });
                this.getSmsSetting();
            })
            .finally(() => {
                this.sms.saving = false;
            }); 
        },
        resetAll(){
            this.openDialog = false;
        }
    }

})
</script>

<style scoped>
.v-dialog.v-dialog--active.v-dialog--persistent{
  max-width: 900px !important;
}
</style>
