<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <div class="">
          <v-card class="elevation-1">
            <div class="tab-panel-">
              <v-tabs v-model="model" color="white" slider-color="primary">
                <v-tab
                  v-for="(tab, i) in tabItems"
                  :key="i"
                  :href="`#tab-${i}`"
                >
                  {{ tab }}
                </v-tab>
              </v-tabs>
            </div>
          </v-card>
        </div>
        <div
          class="dash-container info-tab-section"
          style="width: 100%; margin-top: 0"
        >
          <div v-if="model === 'tab-0'" class="mx-auto mt-0">
            <v-card-text class="py-0">
              <UpdateLog></UpdateLog>
            </v-card-text>
          </div>

          <div v-if="model === 'tab-1'" class="payments">
            <payment></payment>
          </div>
          <v-layout row wrap v-if="model === 'tab-2'">
            <div class="settings flex xs12 mt-5">
              <v-layout row wrap>
                <template v-for="(item, key) in items">
                  <v-flex xs6 :key="key">
                    <v-card height="100%" class="pa-2 setting-container">
                      <v-card-title class="subheading font-weight-bold">
                        {{ item.name }}
                      </v-card-title>
                      <v-divider></v-divider>
                      <v-list dense>
                        <span v-if="item.list === 1">
                          <AcademicSetting></AcademicSetting>
                        </span>

                        <span v-if="item.list === 2">
                          <ExamSetting></ExamSetting>
                        </span>
                        <span v-if="item.list === 3">
                          <br />
                          
                          <BillingSetting></BillingSetting>
                        </span>

                        <span v-if="item.list === 4">
                          <br />
                          <AuthenticationSetting></AuthenticationSetting>
                        </span>
                        <span v-if="item.list === 5">
                          <br />
                          <SmsSetting></SmsSetting>
                        </span>
                      </v-list>
                    </v-card>
                  </v-flex>
                </template>
              </v-layout>
            </div>
          </v-layout>
          <v-layout row wrap v-if="model === 'tab-3'">
            <Token></Token>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";

import Payment from "./../SchoolSetting/components/Payments";
import Token from "./../SchoolSetting/components/Token";
import ExamSetting from "./pages/ExamSetting.vue";
import AuthenticationSetting from "./pages/AuthenticationSetting.vue";
import AcademicSetting from "./pages/AcademicSetting.vue";
import BillingSetting from "./pages/BillingSetting.vue";
import UpdateLog from "./pages/UpdateLog.vue";
import SmsSetting from "./pages/SmsSetting.vue";
export default {
	components: {
		Payment,
		Token,
		ExamSetting,
		AuthenticationSetting,
		AcademicSetting,
		BillingSetting,
		UpdateLog,
		SmsSetting,
	},
	data: () => ({
		model: "tab-2",
		switch1: true,
		bill_generate: "",
		studentLoading: false,
		search: "",

		billingGenerate: [
			{ value: "monthly", text: "Monthly" },
			{ value: "quarterly", text: "quarterly" },
		],

    tabItems: ["Update Log", "Payments", "Settings", "Access Token Manager"],
    items: [
      {
        name: "Academic",
        // calories: 159,
        list: 1,
      },
      {
        name: "Exam",
        // calories: 237,
        list: 2,
      },
      {
        name: "Billing",
        // calories: 262,
        list: 3,
      },
      {
        name: "Authentication",
        // calories: 262,
        list: 4,
      },
      {
        name: "SMS",
        // calories: 262,
        list: 5,
      },
    ],
    options: {
      chart: {
        width: "100%",
      },
      xaxis: {
        categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
      },
    },
    series: [
      {
        name: "eAcademy Data",
        data: [30, 40, 45, 50, 49, 60, 70, 91],
      },
    ],
  }),
  computed: {
    ...mapState(["batch"]),
  },
  mounted() {
    if (this.$route.query.tab === "payments") {
      this.model = "tab-1";
    }
  },

	methods: {},
	beforeDestroy() {
		this.$events.remove("notification");
	},
};
</script>
<style lang="scss">
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  margin-top: 20px;
}
.v-tabs__item {
  color: #888;
}

.switchButton {
  margin-bottom: 18px;
}

.v-tabs__item--active {
  color: #1976d2 !important;
  font-weight: bold;
}

.info {
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.setting-container {
  display: flex;
  flex-direction: column;

  .v-list--dense {
    flex: 1;

    span {
      width: 100%;
      display: flex;
      flex: 1;
      height: 100%;
      flex-direction: column;
    }

    div[role="listitem"]:last-child {
      margin-top: auto;
    }

    /*background-color: red;*/
  }
}

.dash-container {
  min-height: 100px;
  width: 90%;
  /*padding: 40px 0 0 0;*/
  margin: 0 auto;

  div.dash-company-info {
    padding-top: 40px;
    display: flex;
    padding-bottom: 10px;
  }

  h1 {
    color: #555;
    font-weight: 300;

    + p {
      color: #888;
      font-size: 14px;
    }
  }

  .navigate-to {
    flex: 1;
    padding-top: 15px;

    ul li {
      list-style: none;
      display: inline;

      a {
        display: inline-block;
        border: 0.15em solid #c9c9c9;
        margin-left: 10px;
        height: 35px;
        width: 35px;
        text-align: center;
        line-height: 30px;
        border-radius: 50px;
        text-decoration: none;

        &:hover {
          i {
            color: #333 !important;
          }
        }
      }
    }
  }

  .intro {
    flex: 3;
  }

  .submitButton {
    width: 108%;
    background: blue;
    margin-left: -15px;
    margin-top: 6px;
    color: black;
    height: 40px;
  }
}

.update-item {
  margin-bottom: 20px;

  .caption {
    color: #999;

    strong {
      color: #777;
    }
  }
}
</style>
