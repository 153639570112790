<template>
  <div>
    <v-list-item>
      <v-select
        multiple
        outlined
        dense
        label="Disable Authentication On"
        :items="authentication.authentication_disable_options"
        class="pa-0"
        v-model="authentication.selected_options"
      />
    </v-list-item>
    <v-list-item v-if="authentication.selected_options.includes('payment-due')">
      <v-select
        multiple
        outlined
        dense
        label="Fee Head Rule (Block only for Selected Head's Due.)"
        :items="fee_heads"
        class="pa-0"
        v-model="authentication.fee_heads"
      />
    </v-list-item>
    <v-list-item>
      <v-text-field
        v-if="authentication.selected_options.includes('payment-due')"
        outlined
        dense
        label="Payment Due Disabled Reason (< 100 character)"
        placeholder="Will get displayed on authentication failure"
        maxlength="100"
        class="pa-0 ma-0"
        v-model="authentication.disabled_message_for.payment_due"
      />
    </v-list-item>
    <br>
    <v-list-item>
      <br><br>
      <v-select
        outlined
        dense
        label="Set timer for Inactivity Auto Logout"
        :items="autoLogoutTime"
        class="pa-0"
        v-model="authentication.logout_time"
      />
    </v-list-item>

    <v-list-item>
      <br />
      <br />
      <v-checkbox
        style="margin-right: 20px"
        color="error"
        v-model="authentication.status"
      >
        <template v-slot:label>
          <div style="color: #333">
            Authentication Disable Status
            <p style="line-height: 10px">
              <small style="color: #777; font-size: 12px; font-weight: normal">
                Check/Uncheck this to Enable/Disable selected authentication
                disable methods.
              </small>
            </p>
          </div>
        </template>
      </v-checkbox>
    </v-list-item>
    
    

    <v-list-item> </v-list-item>
    <v-list-item></v-list-item>
    <v-list-item></v-list-item>
    <v-list-item></v-list-item>    <v-list-item></v-list-item>
<br>
    <v-list-item>
      <v-btn
        :disabled="authentication.saving"
        :loading="authentication.saving"
        large
        block
        color="info outline"
        @click="authenticationSubmit"
        >Save</v-btn
      >
    </v-list-item>
  </div>
</template>
<script>
export default {
  data: () => ({
    authentication: {

      authentication_disable_options: [
        { value: "payment-due", text: "Payment Due" },
      ],
      selected_options: [],
      fee_heads: [],
      disabled_message_for: {
        payment_due: "",
      },
      status: false,
      saving: false,
      logout_time: 'Never',
    },
    fee_heads: [],
    autoLogoutTime: ['Never', '5min', '10min' , '20min', '30min', '60min']
  }),
  mounted() {
    this.getFeeHead();

    this.getAuthenticationSetting();
  },
  methods: {
    getAuthenticationSetting() {
      this.$rest.get("/api/authentication-setting").then(({ data }) => {
        if (data.transit && data.transit.authentication) {
          this.authentication.selected_options =
            data.transit.authentication.selected_options;
          this.authentication.disabled_message_for =
            data.transit.authentication.disabled_message_for;
          this.authentication.status = data.transit.authentication.status;
          this.authentication.fee_heads = data.transit.authentication.fee_heads;
          this.authentication.logout_time = data.transit.authentication.logout_time  ? data.transit.authentication.logout_time : 'Never';
        }
      });
    },
    authenticationSubmit() {
      this.authentication.saving = true;
      this.$rest
        .post("/api/setting", {
          status: this.authentication.status,
          fee_heads: this.authentication.fee_heads,
          authentication_disable:
            this.authentication.selected_options.join(","),
          logout_time: this.authentication.logout_time,
          disable_message: this.authentication.disabled_message_for,
          flag: "authentication",
        })
        .then(({ data }) => {
          this.$events.fire("notification", { message: data.message });
          this.getAuthenticationSetting();
        })
        .finally(() => {
          this.authentication.saving = false;
        });
    },
    getFeeHead() {
      this.$rest
        .get("/api/fee-head?rowsPerPage=100&withDefaultHeads=true")
        .then(({ data }) => {
          // console.log(data);
          this.fee_heads = data.data.map((item) => {
            return { value: item.title, text: item.title };
          });
        });
    },
  },
};
</script>